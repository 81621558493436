.header-top {
  /* background: #DCE6F1; */
  /* background: #f1f5f8; */
  pointer-events: auto;
  /* -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%); */
  /* padding-bottom: 75; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}

@media (max-width: 480px) {
  .header-top {
    flex-direction: column;
    margin-right: 0;
    /* align-items: flex-start; */
    /* position: relative;
    z-index: 2; */
  }
}

.support-wrapper {
  /* background-color: none; */
  max-width: 450px;
  position: fixed;
}

@media (max-width: 480px) {
  .support-wrapper {
    background-color: #d5e1e9;
    position: fixed;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 90;
  }
}

.burger-icon {
  display: none;
}

@media (max-width: 480px) {
  .burger-icon {
    display: flex;
    margin-right: auto;
  }
}

.support {
  display: none;
}
@media (max-width: 480px) {
  .support {
    display: flex;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 700;
  }
}

.support-big {
  padding-left: 0;
  color: black;
}

@media (max-width: 480px) {
  .support-big {
    display: none;
  }
}

.header-container {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 75px;
  /* margin: 0 auto; */
  width: 100%;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  padding: 0 10px;
  /* justify-content: center; */
}

@media (min-width: 1220px) {
  .header-container {
    width: 1220px;
    padding: 0px;
  }
}

/* @media (max-width: 770px) {
  .header-container {
    justify-content: space-around;
  }
} */

.header-logo {
  display: inline-block;
  margin: 0 16px 0 15px;
  width: 141px;
  font-size: 24px;
}

.header-logo:focus,
.header-logo:active {
  outline: none; /* Remove the default outline */
  background-color: transparent; /* Ensure no background color */
  box-shadow: none; /* Remove any browser default focus/active shadows */
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 1199px) {
  .header-logo {
    margin-right: 0;
  }
}

@media (max-width: 770px) {
  .header-logo {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    order: 2;
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    -o-order: 2;
  }
}

@media (max-width: 480px) {
  .header-logo {
    margin: 0;
  }
}

.header-nav {
  vertical-align: top;
  display: none;
  margin: 0 15px;
}

@media (min-width: 1200px) {
  .header-nav {
    display: inline-block;
  }
}

.menu {
  margin: 0;
  padding: 0;
}

@media (max-width: 770px) {
  .menu {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    width: 100%;
  }
}

.menu-item {
  display: inline-block;
}

@media (max-width: 770px) {
  .menu-item {
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -o-flex: 1 1 auto;
  }
}

@media (max-width: 770px) {
  .menu-item-home {
    display: none;
  }
}

.menu-item-link {
  display: inline-block;
  font-size: 16px;
  line-height: 50px;
  padding: 0 30px;
}

@media (min-width: 1200px) {
  .menu-item-link {
    padding: 0 15px;
  }
}

@media (max-width: 770px) {
  .menu-item-home {
    padding: unset;
    text-align: center;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .menu-item-home {
    padding: 0 15px;
  }
}

.search-bar {
  /* box-sizing: border-box; */
  /* position: relative; */
  display: inline-block;
  display: flex;
  /* justify-content: center; */
  margin-bottom: 0px !important;
  margin-left: 10px;
  /* margin-top: 9px; */
  /* margin-bottom: 9px; */
  vertical-align: top;
  flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  -o-flex: 1;
  /* margin-right: 8px; */
  position: relative;
  box-sizing: inherit;
}

.logout-button {
  border-radius: 30px;
  width: 81px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6090;
  color: white;
  font-family: 'Playfair Display', serif;
}

@media (max-width: 770px) {
  .logout-button {
    display: none;
  }
}

.signup-button {
  border-radius: 30px;
  width: 81px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6090;
  color: white;
  font-family: 'Playfair Display', serif;
}

@media (max-width: 770px) {
  .signup-button {
    display: none;
  }
}

@media (max-width: 770px) {
  .search-bar {
    display: none;
  }
}

.searchbar-form {
  width: 75%;
  /* width: 90%; */
  box-sizing: inherit;
}

.searchbar-input {
  border-radius: 30px;
  height: 30px;
  padding: 4px 26px 4px 35px;
  width: 93%;
  border: 2px solid lightpink;
}

.search-icon-button {
  background: none;
  border: none;
}

.searchbar-icon {
  display: inline-block;
  height: 19px;
  position: absolute;
  width: 18px;
  /* right: 13px; */
  /* right: 10%; */
  top: 9.5px;
  left: 9px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 0;
  background-color: white;
  box-sizing: inherit;
}

@media (max-width: 770px) {
  .searchbar-icon {
    top: 13px;
    left: 21px;
  }
}

.search-icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin-left: 16px;
  margin-right: auto;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 0;
  -moz-flex: 1 1 0;
  -ms-flex: 1 1 0;
  -o-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  -o-order: 1;
  order: 1;
}

@media (min-width: 771px) {
  .search-icon {
    display: none;
  }
}

.header-user-menu-container {
  margin: 0 8px 0 0;
  float: none;
}

@media (max-width: 770px) {
  .header-user-menu-container {
    display: none;
  }
}

.header-user-menu {
  padding: 0;
}

.header-user-menu-item {
  display: inline-block;
}

.menu-icon {
  height: 30px;
  width: 30px;
  margin: 10px 8px;
  vertical-align: middle;
  display: inline-block;
}

.main-header-container {
  position: fixed;
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  top: 0px;
  width: 100%;
  z-index: 70;
  /* display: flex; */
  /*  */
}

.profile-icon {
  display: none;
}

@media (max-width: 770px) {
  .profile-icon {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    -webkit-flex: 1 1 0;
    -moz-flex: 1 1 0;
    -ms-flex: 1 1 0;
    -o-flex: 1 1 0;
    flex: 1 1 0;
    margin-left: auto;
    margin-right: 16px;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    -o-order: 3;
    order: 3;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
}

.modal-close {
  left: 16px;
  position: fixed;
  right: auto;
  top: 16px;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.modal-content {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  height: 100%;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  position: fixed;
  right: 0;
  width: 85%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
@media (max-width: 780px) {
  .modal-content {
    padding: 32px;
    width: 600px;
  }
}

.modal-menu {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  padding: 0 16px;
}

.modal-menu-container {
  padding: 0 24px;
}

.modal-profile {
  border-bottom: 1px solid #d8d8d8;
  padding: 12px 0;
}

.modal-profile-image {
  box-sizing: inherit;
  flex-shrink: 0;
  margin-right: 8px;
  height: 75px;
  width: 75px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
}

.modal-profile-description {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 1%;
}

.text-bold {
  font-weight: bold;
}

.menu-list {
  margin-left: -16px;
}

.menu-list-link {
  display: block;
  font-size: 14px;
  line-height: 32px;
  padding: 0 16px;
}

/* body, p, ol, ul, td, th {
      font-size: 12px;
      margin: 0;
      line-height: 18px;
      padding: 0;
  } */

.header-bottom {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: auto;
  position: relative !important;
  z-index: 1 !important;
}

.header-bottom-nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  height: 50px;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  background: #f0f5fe;
}

@media (max-width: 780px) {
  .header-bottom-nav {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .header-bottom-nav {
    display: none;
  }
}

.header-bottom-padding {
  padding-bottom: 82px;
}

@media (min-width: 1200px) {
  .header-bottom-padding {
    padding-bottom: 90px;
  }
}

.header-relative {
  flex: 1;
  position: relative;
}

@media (max-width: 770px) {
  .header-relative {
    flex: unset !important;
  }
}

.smol-search {
  display: none;
}

@media (max-width: 770px) {
  .smol-search {
    display: block;
    position: relative;
    top: 26px;
    height: 55px;
    margin-bottom: 15px;
    padding: 5px 12px;
    background-color: #f1f5f8;
    margin-top: -30px;
  }
}

.search-top {
  position: absolute;
  left: 21px;
  width: 500px;
  background-color: white;
  padding: 10px;
}

@media (max-width: 770px) {
  .search-top {
    top: 87px;
  }
}

.hidden {
  display: none; /* Hides the element completely */
}
