.outlet-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1240px;
  align-self: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

@media (max-width: 480px) {
  .outlet-wrapper {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
