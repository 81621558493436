.main {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (max-width: 480px) {
  .main {
    grid-template-columns: none;
    /* display: flex;
    grid-template-columns: none; */
    /* flex-direction: column;
    align-items: flex-start; */
    /* background-color: #f8f8f8; */
  }
}

.placeholder {
  /* flex: 1; */
  /* flex-grow: 1; */
  align-content: center;
  order: 1;
  /* padding-top: 100; */
}

@media (max-width: 480px) {
  .placeholder {
    order: 2;
  }
}

.coming-soon {
  font-size: 55px;
  margin-bottom: 0.5rem;
  text-align: center;
  font-family: Lora, serif;
}

@media (max-width: 767px) {
  .coming-soon {
    font-size: 40px;
    margin-bottom: 0;
  }
}

.flatlay {
  /* height: 90vh; */
  /* height: 90vh; */
  width: 100%;
  max-width: 600;
  /* max-width: 640px; */
}

@media (max-width: 767px) {
  .flatlay {
    width: 100%;
    height: auto;
  }
}

.coming-soon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20;
  padding-right: 20;
  /* width: 50vw; */
  width: 100%;
  /* max-width: 600; */
  order: 2;
}

@media (max-width: 480px) {
  .coming-soon-wrapper {
    /* width: 100vw; */
    order: 1;
  }
}
