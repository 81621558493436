.recaptcha-wrapper {
  transform: none; /* Ensure no transformation is applied by default */
  transform-origin: 0 0;
}

@media (max-width: 400px) {
  .recaptcha-wrapper {
    transform-origin: 0 0;
    align-self: center;
  }
}
